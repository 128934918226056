import './App.css';
import '@aws-amplify/ui-react/styles.css';
import { Image, Tabs, TabItem, Collection, Card, View, Flex, Badge, Divider, Heading, Button, ToggleButton, Authenticator, AccountSettings, Text, Table, TableRow, TableBody, TableHead, TableCell } from '@aws-amplify/ui-react';
import { Amplify, API, Notifications } from 'aws-amplify';
import awsExports from './aws-exports';
import { createUser, createEventUser, deleteEventUser } from './graphql/mutations';
import { listEvents, listUsers, listEventUsers, getEvent, getUser } from './graphql/queries';
Amplify.configure(awsExports);

function InvoiceTable({ items }) {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell as="th">Name</TableCell>
          <TableCell as="th">Date</TableCell>
          <TableCell as="th">Price</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map((item, index) => (
          <TableRow key={index}>
            <TableCell>{item.name}</TableCell>
            <TableCell>{item.date}</TableCell>
            <TableCell>30,000</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

function App() {
  const items = [
    {
      title: 'Milford - Room #1',
      badges: ['Waterfront', 'Verified'],
    },
    {
      title: 'Milford - Room #2',
      badges: ['Mountain', 'Verified'],
    },
  ];

  const getUsers = () => {
    var users = [];
    API.graphql({ query: listUsers }).then((res) => {
      res.data.listUsers.items.forEach((item) => {
        users.push(item);
      });
    });
    return users;
  };

  const getUserId = (email) => {
    var userId = '';
    API.graphql({
      query: listUsers,
      variables: {
        filter: {
          email: {
            eq: email
          }
        }
      }
    }).then((res) => {
      userId = res.data.listUsers.items[0].id;
    });
    return userId;
  };

  const getAttendees = (eventId) => {
    const attendees = [];
    API.graphql({
      query: listEventUsers,
      variables: {
        filter: {
          eventId: {
            eq: eventId
          }
        }
      }
    }).then((res) => {
      res.data.listEventUsers.items.forEach((item) => {
        API.graphql({
          query: getUser,
          variables: {
            id: item.userId
          }
        }).then((res) => {
          attendees.push(res.data.getUser);
        });
      });
    });

    return attendees;
  };

  const getAllEvents = () => {
    var events = [];
    API.graphql({ query: listEvents }).then((res) => {
      res.data.listEvents.items.forEach((item) => {
        const eventId = item.id;
        const attendees = getAttendees(eventId);
        item['attendees'] = attendees;
        events.push(item);
      });
    });
    return events;
  };

  const getMyEvents = (email) => {
    var events = [];
    API.graphql({
      query: listUsers,
      variables: {
        filter: {
          email: {
            eq: email
          }
        }
      }
    }).then((res) => {
      const userId = res.data.listUsers.items[0].id;
      API.graphql({
        query: listEventUsers,
        variables: {
          filter: {
            userId: {
              eq: userId
            }
          }
        }
      }).then((res) => {
        res.data.listEventUsers.items.forEach((item) => {
          API.graphql({
            query: getEvent,
            variables: {
              id: item.eventId
            }
          }).then((res) => {
            events.push(res.data.getEvent);
          });
        });
      });
    });
    return events;
  };
  
  const handleSuccess = () => {
    alert('Password changed successfully');
  };

  const formFields = {
      signUp: {
        name: {
          order: 1,
        },
        email: {
          order:2,
        },
        password: {
          order: 3,
        },
        confirm_password: {
          order: 4,
        },
      },
    };

  return (
    <Flex direction="column">
      <Image
        alt="Logo"
        src="/logo.png"
        onClick={() => alert('📸 Say cheese!')}
      />
      <Authenticator formFields={formFields}>
        {({ signOut, user }) => (
          <main>
            <Tabs justifyContent="flex-start">
              <TabItem title="Profile">
                <Flex direction="column">
                  <Card variation='elevated'>
                    <Flex direction="column">
                      <Text fontWeight="bold">{user.attributes['name']}</Text>
                      <Text fontWeight="bold">{user.attributes['email']}</Text>
                      <Button variation="primary" isFullWidth onClick={signOut}>Sign Out</Button>
                    </Flex>
                  </Card>
                  <Card variation='elevated'>
                    <Flex direction="column">
                      <AccountSettings.ChangePassword onSuccess={handleSuccess}/>
                    </Flex>
                  </Card>
                  <Card variation='elevated'>
                    <Flex direction="column">
                      <AccountSettings.DeleteUser/>
                    </Flex>
                  </Card>
                </Flex>
              </TabItem>
              <TabItem title="Schedule">
                <Collection
                  items={getAllEvents()}
                  type="list"
                  direction="column"
                  wrap="nowrap"
                >
                  {(item, index) => (
                    <Card
                      key={index}
                      borderRadius="medium"
                      variation="outlined"
                    >
                      <Flex direction="column" gap="0rem">
                        <Image
                          src={item.thumbnail}
                          alt={item.name}
                        />
                        <View padding="1rem">
                          <Text variation="secondary" textAlign="right">{item.date}</Text>
                          <Text textAlign="center" fontSize="1.5rem" lineHeight="2rem" variation="primary">{item.name}</Text>
                        </View>
                        <ToggleButton
                          variation="primary"
                          isFullWidth
                          isPressed={item.attendees.map((x) => x.email).includes(user.attributes['email'])}
                          onClick={async() => {
                            const email = user.attributes['email'];
                            const res = await API.graphql({
                              query: listUsers,
                              variables: {
                                filter: {
                                  email: {
                                    eq: email
                                  }
                                }
                              }
                            });
                            const userId = res.data.listUsers.items[0].id;
                            const eventId = item.id;
                            const eu = await API.graphql({
                              query: listEventUsers,
                              variables: {
                                filter: {
                                  userId: {
                                    eq: userId
                                  },
                                  eventId: {
                                    eq: eventId
                                  }
                                }
                              }
                            });
                            if (eu.data.listEventUsers.items.length > 0) {
                              const e = await API.graphql({
                                query: getEvent,
                                variables: {
                                  id: eventId
                                }
                              });
                              const d = await API.graphql({
                                query: deleteEventUser,
                                variables: {
                                  input: {
                                    id: eu.data.listEventUsers.items[0].id
                                  }
                                }
                              });
                            } else {
                              const res = await API.graphql({
                                query: createEventUser,
                                variables: {
                                  input: {
                                    userId: userId,
                                    eventId: eventId
                                  }
                                }
                              });
                            }
                          }}
                        >
                          Attend
                        </ToggleButton>
                        <Divider />
                        <Collection
                          items={item.attendees}
                          type="list"
                          direction="row"
                          wrap="wrap"
                        >
                          {(item, index) => (
                            <Badge size="small" variation="info" key={index}>{item.name}</Badge>
                          )}
                        </Collection>
                      </Flex>
                    </Card>
                  )}
                </Collection>
              </TabItem>
              <TabItem title="Invoice">
                <InvoiceTable items={getMyEvents()} />
              </TabItem>
            </Tabs>
          </main>
        )}
      </Authenticator>
    </Flex>
  );
}

export default App;
